<template>
  <v-container fluid>
    <v-row>
      <!-- Card for Product Form -->
      <v-col cols="12">
        <v-card class="elevation-3 rounded-lg">
          <v-card-title class="headline text-center">Ürün Tanımla</v-card-title>
          <v-card-text>
            <v-form ref="addForm" @submit.prevent="addData" v-model="valid">
              <!-- Barcode Input -->
              <v-text-field
                v-model="newData.barcode"
                label="Barcode Giriniz..."
                outlined
                dense
                required
                :rules="barcodeRules"
                placeholder="Örneğin: BKITHOME-123"
                prepend-icon="mdi-barcode-scan"
                class="mb-4"
              ></v-text-field>

              <!-- Komisyon Input -->
              <v-text-field
                v-model="newData.comission"
                label="Komisyon Giriniz..."
                type="number"
                outlined
                dense
                required
                :rules="commissionRules"
                placeholder="Komisyon oranını giriniz"
                prepend-icon="mdi-percent"
                class="mb-4"
              ></v-text-field>

              <!-- Maliyet Input -->
              <v-text-field
                v-model="newData.cost"
                label="Maliyet Giriniz..."
                type="number"
                outlined
                dense
                required
                :rules="costRules"
                placeholder="Maliyet tutarını giriniz"
                prepend-icon="mdi-currency-try"
                class="mb-4"
              ></v-text-field>

              <!-- Desi Input -->
              <v-text-field
                v-model="newData.desi"
                label="Desi Giriniz..."
                type="number"
                outlined
                dense
                required
                :rules="desiRules"
                placeholder="Desi değerini giriniz"
                prepend-icon="mdi-package-variant"
                class="mb-4"
              ></v-text-field>

              <!-- Submit Button -->
              <v-btn
                color="primary"
                large
                block
                @click="addData"
                :disabled="!valid"
                class="submit-btn"
              >
                Ürün Ekle
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Card for Product List -->
      <v-col cols="12">
        <v-card class="elevation-3 rounded-lg">
          <v-card-title class="headline text-center">Ürün Listesi</v-card-title>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Barcode</th>
                    <th>Komisyon</th>
                    <th>Maliyet</th>
                    <th>Desi</th>
                    <th>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in data" :key="index">
                    <td>
                      <v-text-field
                        v-model="item.barcode"
                        label="Barcode"
                        hide-details
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.comission"
                        label="Komisyon"
                        type="number"
                        hide-details
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.cost"
                        label="Maliyet"
                        type="number"
                        hide-details
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.desi"
                        label="Desi"
                        type="number"
                        hide-details
                        dense
                      />
                    </td>
                    <td class="text-center">
                      <v-btn @click="saveData(index)" color="primary" small>Kaydet</v-btn>
                      <v-btn @click="deleteItem(index)" color="error" small>Sil</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      newData: {
        barcode: '',
        comission: 0,
        cost: 0,
        desi: 0
      },
      valid: false, // Form validasyon kontrolü
      barcodeRules: [(v) => !!v || 'Barcode is required'],
      commissionRules: [
        (v) => !!v || 'Komisyon is required',
        (v) => !isNaN(v) || 'Komisyon must be a number'
      ],
      costRules: [
        (v) => !!v || 'Maliyet is required',
        (v) => !isNaN(v) || 'Maliyet must be a number'
      ],
      desiRules: [
        (v) => !!v || 'Desi is required',
        (v) => !isNaN(v) || 'Desi must be a number'
      ]
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      fetch("https://kitpanel-33ba2-default-rtdb.firebaseio.com/costs/trendyol.json")
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            this.data = Object.values(data); // Convert the object to an array
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    },
    addData() {
      if (this.$refs.addForm.validate()) {
        const newItem = { ...this.newData };
        this.data.push(newItem);

        fetch("https://kitpanel-33ba2-default-rtdb.firebaseio.com/costs/trendyol.json", {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.data)
        })
          .then((response) => {
            if (response.ok) {
              this.fetchData(); // Refresh data
              this.newData = { barcode: '', comission: 0, cost: 0, desi: 0 };
            } else {
              console.error('Error adding data:', response);
            }
          })
          .catch((error) => {
            console.error('Error adding data:', error);
          });
      }
    },
    saveData(index) {
      // Save data directly after clicking save button
      fetch("https://kitpanel-33ba2-default-rtdb.firebaseio.com/costs/trendyol.json", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.data)
      })
        .then((response) => {
          if (!response.ok) {
            console.error('Error saving data:', response);
          }
        })
        .catch((error) => {
          console.error('Error saving data:', error);
        });
    },
    deleteItem(index) {
      this.data.splice(index, 1);

      fetch("https://kitpanel-33ba2-default-rtdb.firebaseio.com/costs/trendyol.json", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.data)
      })
        .then((response) => {
          if (response.ok) {
            this.fetchData(); // Refresh data
          } else {
            console.error('Error deleting data:', response);
          }
        })
        .catch((error) => {
          console.error('Error deleting data:', error);
        });
    }
  }
};
</script>

<style scoped>
/* Card Styling */
.v-card {
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.v-btn {
  margin-top: 10px;
  width: 100%;
}

v-simple-table th {
  text-align: left;
  padding: 10px;
  background-color: #f5f5f5;
}

v-simple-table td {
  padding: 10px;
  border-top: 1px solid #ddd;
}

v-btn {
  margin: 2px;
}

.submit-btn {
  font-weight: 500;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  background-color: #1976d2;
}

/* Focus effect */
.v-input--is-focused .v-input__control {
  border-color: #1976d2;
}

/* Input Field Styling */
.v-text-field {
  margin-bottom: 16px;
}

.v-input__control .v-input__append-inner {
  display: none;
}

.v-text-field input {
  font-size: 1rem;
}

/* Prepend icon styles */
.v-text-field .v-input__prepend-inner .v-icon {
  color: #1976d2;
}
</style>
